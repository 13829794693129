import { WithinFilterDto } from '@/lib/shared/dto/within-filter.dto';
import { SEARCH_ROUTES } from '@/utils/constants';

import { Bounds } from '../map/types';
import { SearchType } from './search-type.enum';

export const CURRENT_LOCATION_VALUE = 'CurrentLocation';

export function getSearchValueFromQuery(query?: string | null) {
  if (query) {
    if (!query || query === CURRENT_LOCATION_VALUE) return undefined;
    return decodeURIComponent(query);
  }
  return undefined;
}

export function getSearchType(route: string) {
  if (route.startsWith('/meeting-rooms')) {
    return SearchType.MeetingRoom;
  }
  if (route.startsWith('/locations')) {
    return SearchType.Location;
  }
  return SearchType.Coworking;
}

export function getRoute(searchType: SearchType) {
  if (searchType === SearchType.MeetingRoom) {
    return SEARCH_ROUTES.MEETING_ROOMS;
  }
  if (searchType === SearchType.Coworking) {
    return SEARCH_ROUTES.COWORKING;
  }
  return SEARCH_ROUTES.LOCATIONS;
}

export function mapBounds(bounds: Bounds): WithinFilterDto {
  return {
    northEastPoint: {
      longitude: bounds.ne.lng,
      latitude: bounds.ne.lat,
    },
    southWestPoint: {
      longitude: bounds.sw.lng,
      latitude: bounds.sw.lat,
    },
  };
}
